
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import { Watch } from "vue-property-decorator";
    import apiClient from "@/utilities/ApiClient";
    import store from "@/store/store";
    import utils from "@/utilities/Utils";
    import { ChartFilterOptions } from "@/model/Enums";
    import { ChartRequest } from "@/model/api/ChartRequest";

    @Component({})
    export default class Chartfilters extends Vue {

        // properties

        @Prop({}) private tag!: string;
        @Prop({}) private request!: ChartRequest;
        @Prop({ default: false }) private range!: boolean;
        @Prop({ default: false }) private adviceReport!: boolean;
        @Prop({ default: false }) private isIndependent!: boolean;

        private startConfig = { format: "MMMM yyyy" }
        private endConfig = { format: "MMMM yyyy", maxDate: new Date() }

        private initialLoadComplete: boolean = false;
        private isLoading: boolean = false;

        private get showAdviceReport(): boolean {
            const currentDate = new Date();
            const endDate = new Date(this.request.endDate);
            return this.adviceReport && (currentDate.getFullYear() !== endDate.getFullYear() || currentDate.getMonth() !== endDate.getMonth());
        }

        // watchers

        @Watch("$store.state.layoutInitDone", { immediate: true, deep: true })
        private async onLayoutInit(initComplete: boolean) {
            if (!initComplete) return;

            if (this.$store.state.agreementNumber != this.request.agreementNumber || 
                this.request.dropAccountNumber != this.$store.state.dropAccount ||
                !utils.hasDateValue(this.request.startDate) ||
                !utils.hasDateValue(this.request.endDate)) {
                await this.init();     
                this.initialLoadComplete = true;
            }
        }

        @Watch("request.startDate")
        private async onStartChanged() {
            if (this.isIndependent) {
                if (!this.isLoading && this.initialLoadComplete && this.range && this.request.selectedFilter == ChartFilterOptions.SpecificDates){
                    await this.$emit("refresh", this.tag);
                }
            }
            else {
                if (this.$store.state.startDate == this.request.startDate) {
                    return;
                }
                else {
                    await store.dispatch("setStartDate", this.request.startDate);

                    if (!this.isLoading && this.initialLoadComplete && this.range && this.request.selectedFilter == ChartFilterOptions.SpecificDates){
                        await this.$emit("refresh", this.tag);
                    }
                }
            }
        }

        @Watch("request.endDate")
        private async onEndDate() {
            if (this.isIndependent) {
                if (!this.isLoading && this.initialLoadComplete && this.request.selectedFilter == ChartFilterOptions.SpecificDates) {
                    await this.$emit("refresh", this.tag);
                }
            }
            else {
                if (this.$store.state.endDate == this.request.endDate) {
                    return;
                }
                else {
                    await store.dispatch("setEndDate", this.request.endDate);

                    if (!this.isLoading && this.initialLoadComplete && this.request.selectedFilter == ChartFilterOptions.SpecificDates) {
                        await this.$emit("refresh", this.tag);
                    }
                }
            }
        }

        @Watch("request.selectedFilter")
        public async onFilterChanged(value: ChartFilterOptions) {
            if (this.isIndependent) {
                if (!this.isLoading && this.initialLoadComplete) {
                    await this.$emit("refresh", this.tag);
                }
            }
            else {
                if (this.$store.state.selectedFilter == this.request.selectedFilter) {
                    return;
                }
                else {
                    await store.dispatch("setSelectedFilter", this.request.selectedFilter);

                    if (!this.isLoading && this.initialLoadComplete) {
                        await this.$emit("refresh", this.tag);
                    }
                }                
            }
        }

        @Watch("$store.state.startDate")
        private async onStoreStartDateChanged(value: Date) {
            if (!this.isIndependent && this.request.startDate != value) {
                this.request.startDate = value;
            
                if (!this.isLoading && value != null && this.initialLoadComplete && this.range && this.request.selectedFilter == ChartFilterOptions.SpecificDates) {
                    await this.$emit("refresh", this.tag);
                }
            }
        }

        @Watch("$store.state.endDate")
        private async onStoreEndDateChanged(value: Date) {
            if (!this.isIndependent && this.request.endDate != value) {
                this.request.endDate = value;

                if (!this.isLoading && value != null && this.initialLoadComplete && this.request.selectedFilter == ChartFilterOptions.SpecificDates) {
                    await this.$emit("refresh", this.tag);
                }
            }
        }

        @Watch("$store.state.selectedFilter")
        private async onStoreSelectedFilterChanged(value: ChartFilterOptions) {
            if (!this.isIndependent && this.request.selectedFilter != value) {
                this.request.selectedFilter = value;

                if (!this.isLoading && value != null && this.initialLoadComplete) {
                    await this.$emit("refresh", this.tag);
                }
            }
        }

        @Watch("$store.state.includeInactive")
        private async onStoreIncludeInactiveChanged(value: boolean) {
            this.request.includeInactive = value;

            if (!this.isLoading && this.initialLoadComplete) {
                await this.$emit("refresh", this.tag);
            }
        }

        // methods

        private filterButtonVariant(option: ChartFilterOptions): string {
            return option == this.request.selectedFilter ? "primary" : "outline-secondary";
        }

        private init = async () => {
            this.isLoading = true;

            const includeInactive = this.$store.state.includeInactive;
            this.request.includeInactive = includeInactive;

            if (!this.isIndependent && this.$store.state.startDate != null && this.$store.state.endDate != null && this.$store.state.selectedFilter != null) {
                const startDate = this.$store.state.startDate;
                const endDate = this.$store.state.endDate;
                const selectedFilter = this.$store.state.selectedFilter;

                this.request.startDate = startDate;
                this.request.endDate = endDate;
                this.request.selectedFilter = selectedFilter;
            }
            else {
                await this.getDefaultDates();
            }

            this.isLoading = false;

            await new Promise(r => setTimeout(r, 500));
            await this.$emit("refresh", this.tag);
        }

        private async getDefaultDates() {
            const agreementNumber = this.$store.state.agreementNumber;
            const dropAccount = this.$store.state.dropAccount ?? 0;

            const dateString = await apiClient.get(`api/agreement/latestPurchaseInfoReceived?agreementNumber=${agreementNumber}&dropAccountNumber=${dropAccount}`);
            const date = new Date(dateString);

            const startDate = new Date(date.getFullYear() - 1, date.getMonth(), 1);
            const endDate = new Date(date.getFullYear(), date.getMonth(), 1);

            if (!this.isIndependent) {
                await store.dispatch("setStartDate", startDate);
                await store.dispatch("setEndDate", endDate);
                await store.dispatch("setSelectedFilter", ChartFilterOptions.SpecificDates);
            }
            else {
                this.request.startDate = startDate;
                this.request.endDate = endDate;
                this.request.selectedFilter = ChartFilterOptions.SpecificDates;
            }
        }

        private downloadAdviceReports() {
            this.$emit("downloadAdviceReports");
        }

    }
