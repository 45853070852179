import { ChartFilterOptions } from "@/model/Enums";

export class ChartRequest {

    agreementNumber: number = 0;
    dropAccountNumber: number = 0;
    dropAccountNumbers: Array<number> = [];
    bidfood: boolean = false;
    categoryID: number = 0;
    supplierID: number = 0;
    
    selectedFilter: ChartFilterOptions = 0;
    startDate: Date = new Date();
    endDate: Date = new Date();
    includeInactive: boolean = false;

    showAverage: boolean = false;
    showTarget: boolean = false;
    cprpdTarget: number = 0;

}