
    import Vue from 'vue';
    import Component from "vue-class-component";
    import ApiClient from "@/utilities/ApiClient";
    import Utils from "@/utilities/Utils";
    import ChartFilters from "@/components/ChartFilters.vue";
    import { ActiveElement, Chart, ChartEvent, LegendItem, registerables } from "chart.js";
    import { Watch } from "vue-property-decorator"; 
    import { ChartData } from "@/model/ChartData";
    import { ChartRequest } from "@/model/api/ChartRequest";
    import { Lookup } from "@/model/Lookup";
    import { mapData } from "@/utilities/DataMapping";
    import PurchasesDialogue from '@/components/PurchasesDialogue.vue';
    import * as toastr from "toastr";

    export enum CategoriesTotalType {
        Unknow = 0,
        Category = 1,
        Supplier = 2
    }

    @Component({ components: { ChartFilters, PurchasesDialogue } })
    export default class Categories extends Vue {

        //
        // -- lifecycle hooks
        //

        async mounted() {            
            Chart.register(...registerables);
        }

        //
        // -- properties
        //

        private initDone = false;
        private categories: Array<Lookup> = [];
        private suppliers: Array<Lookup> = [];

        private hiddenDropAccounts: Array<string> = [];

        private apiMethod = "Spend";
        request: ChartRequest = new ChartRequest();

        private startConfig = {
            format: "MMMM yyyy"
        }
        private endConfig = {
            format: "MMMM yyyy",
            maxDate: new Date()
        }

        private chartData: ChartData<Date> = new ChartData<Date>();
        private chart: any = undefined;

        //
        // -- computed properties
        //

        get page(): string {
            if (!Utils.isEmptyId(this.request.categoryID) && this.categories.length > 0) {
                const category = this.categories.filter(cat => cat.id === this.request.categoryID)[0];
                return category.description;
            } else if (!Utils.isEmptyId(this.request.supplierID) && this.suppliers.length > 0) {
                const supplier = this.suppliers.filter(sup => sup.id === this.request.supplierID)[0];
                return supplier.description;
            } else if (Utils.isEmptyId(this.request.categoryID) && Utils.isEmptyId(this.request.supplierID) && this.request.bidfood) {
                return "Bidfood";
            } else if (Utils.isEmptyId(this.request.categoryID) && Utils.isEmptyId(this.request.supplierID) && !this.request.bidfood) {
                return "All Categories";
            }
            return "";
        }

        get cprpd(): boolean {
            return this.apiMethod !== "Spend";
        }

        set cprpd(checked: boolean) {
            this.apiMethod = checked ? "Cprpd" : "Spend";
        }
        
        // table properties/methods

        get tableFields(): Array<any> {
            const fields: Array<any> = [];
            fields.push({
                key: 'customer',
                label: "",
                stickyColumn: true,
                isRowHeader: true
            });
            this.chartData.labels.forEach((label: string) => fields.push(label));
            return fields
        }

        get tableData(): Array<any> {
            const rows: Array<any> = [];

            this.chartData.datasets
                .filter(dataset => this.cprpd || this.hiddenDropAccounts.indexOf(dataset.id) === -1)
                .forEach(dataset => {
                    const obj: any = {};
                    obj.customer = dataset.label
                    
                    for (let i = 0; i < dataset.data.length; i++) {
                        const column = this.tableFields[i + 1];
                        obj[column] = dataset.data[i];
                    }
                    
                    rows.push(obj);
                });

            if (rows.length > 1 && !this.cprpd) {
                const obj: any = {};
                obj.customer = "Total";
                obj.isTotals = true;

                for (let index = 1; index < this.tableFields.length; index++) {
                    const column: string = this.tableFields[index];
                    const total = rows.reduce((runningTotal, row) => runningTotal + +row[column], 0);
                    obj[column] = total;
                }

                rows.push(obj);
            }
            else if (this.cprpd) {
                const avgRow = rows[rows.length - 1];
                avgRow.isTotals = true
            }

            return rows;
        }

        tableRowClass(item: any, type: any) {
            if (!item || type !== "row") return;
            if (item.isTotals) return "totalsRow";
        }

        //
        // --  watchers
        //

        @Watch("$store.state.layoutInitDone", { immediate: true, deep: true })
        private async onLayoutInit(initComplete: boolean) {
            if (!initComplete) return;

            this.initDone = false;

            this.request.agreementNumber = this.$store.state.agreementNumber;
            this.hiddenDropAccounts.length = 0;

            await this.getCategories();
            await this.getSuppliers();

            this.initDone = true;
        }

        @Watch("$route", { immediate: true, deep: true })
        private onRouteChanged() {
            this.request.bidfood = this.$route.params.categoryID === "Bidfood";
            this.request.categoryID = this.$route.params.categoryID === undefined || this.$route.params.categoryID === "Bidfood" ? 0 : +this.$route.params.categoryID;
            this.request.supplierID = this.$route.params.supplierID === undefined ? 0 : +this.$route.params.supplierID;
            this.load();
        }

        @Watch("apiMethod")
        private async onApiMethodChanged() {
            if (!this.initDone) return; 

            await this.load()
        }

        //
        // -- methods
        //

        private async getCategories() {
            const serverData = await ApiClient.get("api/agreement/categories");
            this.categories = mapData<Array<Lookup>>(serverData, Lookup.mapping);
        }

        private async getSuppliers() {
            const serverData = await ApiClient.get(`api/agreement/suppliers?id=${this.$store.state.agreementNumber}`);
            this.suppliers = mapData<Array<Lookup>>(serverData, Lookup.mapping);
        }

        async load() {
            if (!this.initDone) return;

            const response = await ApiClient.post(`api/agreement/categorySupplier${this.apiMethod}`, this.request);
            this.chartData.update(response);
            this.updateChart();

            if (this.cprpd) {
                if (this.hiddenDropAccounts.length == 0) {
                    this.hiddenDropAccounts.push(...this.chartData.datasets.filter(d => d.id != "-1").map(d => d.id));
                }
            }
            else {
                if (this.hiddenDropAccounts.length == this.chartData.datasets.length) {
                    this.hiddenDropAccounts.length = 0;
                }
            }

            this.updateChartVisibleDatasets(this.chart);
        }

        // monthly spend chart

        private onDatasetClicked(event: ChartEvent, legendItem: LegendItem, legend: any) {
            const datasetIndex = legendItem.datasetIndex!;

            const dataset = this.chartData.datasets[datasetIndex];

            if (this.hiddenDropAccounts.length === 0) {
                this.chartData.datasets
                    .filter(d => dataset.id !== d.id)
                    .forEach(d => {
                        this.hiddenDropAccounts.push(d.id)
                    });
            }
            else if (this.hiddenDropAccounts.indexOf(dataset.id) > -1) {
                this.hiddenDropAccounts.splice(this.hiddenDropAccounts.indexOf(dataset.id), 1);
            }
            else {
                this.hiddenDropAccounts.push(dataset.id)

                if (this.hiddenDropAccounts.length === this.chartData.datasets.length) {
                    this.hiddenDropAccounts = [];
                }
            }

            this.updateChartVisibleDatasets(this.chart)
        }

        private updateChartVisibleDatasets(chart: Chart) {
            chart?.data.datasets.forEach((_dataset, index) => {
                const datasetID = this.chartData.datasets[index].id;
                const hidden = this.hiddenDropAccounts.indexOf(datasetID) > -1;
                const meta = (chart as Chart).getDatasetMeta(index);
                meta.hidden = hidden
            });
            chart?.update();
        } 

        private drawChart() {
            // get canvas dom element
            const canvas = (this.$refs.categorySupplierChart as HTMLCanvasElement);
            if (canvas == undefined) return;

            // get context from canvas
            const ctx = canvas.getContext('2d');
            if (ctx == undefined) return;

            // clear chart if already exists
            if (this.chart !== undefined) {
                this.chart.destroy()
            }

            // create chart
            this.chart = new Chart(ctx!, {
                type: "bar",
                data: {
                    datasets: []
                 },
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        x: { stacked: !this.cprpd },
                        y: { 
                            stacked: !this.cprpd,
                            suggestedMax: this.cprpd ? 2 : 50,
                            ticks: {
                                callback: (value, _index, _values) => {
                                    return Utils.toMoney(+value);
                                }
                            }
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: this.page,
                            font: {
                                size: 18
                            },
                            padding: {
                                top: 0,
                                bottom: 10
                            }
                        },
                        legend: {
                            display: this.$store.state.canChangeCustomer,
                            position: "right",
                            onClick: this.onDatasetClicked
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem: any): string => {
                                    return `${tooltipItem.dataset.label}: ${Utils.toMoney(tooltipItem.raw)}`;
                                }
                            }
                        }
                    },
                    onClick: this.chartClicked
                }
            });
        }

        /* TODO - view/download chart data */
        private chartClicked (event: ChartEvent, elements: ActiveElement[], chart: Chart) {
            if (this.cprpd) return;
            
            if (!Utils.isEmptyId(this.request.supplierID)) {
                toastr.warning("Sorry, we're unable to show purchases for third party suppliers")
                return;
            }

            if (!elements.length) return;

            const element = elements[0]

            const dataSet = chart.data.datasets[element.datasetIndex] as any;
            const dropAccount = +dataSet.id;
            const monthYear = this.chartData.labelValues[element.index];

            const dialogue = this.$refs.purchaseLinesDialogue as PurchasesDialogue;
            dialogue.viewPurchaseLines({
                agreementNumber: this.request.agreementNumber,
                dropAccountNumber: dropAccount,
                date: monthYear,
                categoryID: this.request.categoryID,
                includeInactive: this.request.includeInactive,
                itemsPerPage: 20,
                pageNumber: 0
            })
        }

        private updateChart() {
            // create chart 
            this.drawChart()

            // double check chart has been created
            if (this.chart === undefined) {
                return;
            }
            
            // update datasets
            this.chart.data = this.chartData;            
            this.chart.update();
        }

    }

